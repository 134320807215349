import React from 'react'
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";

function BannerSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 1200,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              initialSlide: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              arrows: false,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className='banner-slide'>
        <Slider {...settings} className="responsive banner-slider">
        <div className="g-0">
          <img src="assets/images/banner/1.webp" alt="Mobile App Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">Mobile App Design</h5>
              <p>Crafted a user-friendly multi-restaurant mobile app for seamless food ordering.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/5.webp" alt="Web App Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">Web App Design</h5>
              <p>Developed a restaurant web app with responsive and interactive design.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/6.webp" alt="UI/UX Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">UI/UX Design</h5>
              <p>Designed device compatible interfaces for an enhanced user experience.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/7.webp" alt="SEO Optimization" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">SEO Optimization</h5>
              <p>Optimized web and app designs to improve search engine rankings and visibility.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/2.webp" alt="Custom App Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">Custom App Design</h5>
              <p>Created tailor-made mobile applications to meet unique client requirements.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/3.webp" alt="E-Commerce App Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">E-Commerce App Design</h5>
              <p>Developed feature-rich e-commerce apps with intuitive shopping experiences.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/7.webp" alt="App Design Highlights" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">App Design Highlights</h5>
              <p>Showcased app designs focusing on aesthetics and usability across platforms.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/5.webp" alt="Business App Design" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">Business App Design</h5>
              <p>Created business-focused apps to streamline workflows and improve efficiency.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/8.webp" alt="Web Design Innovations" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">Web Design Innovations</h5>
              <p>Innovative web designs that deliver engaging user experiences and performance.</p>
          </div>
      </div>
      <div className="g-0">
          <img src="assets/images/banner/6.webp" alt="App Design Enhancements" />
          <div className="content-box-2 mt-4 px-4">
              <h5 className="slider-title fw-bold">App Design Enhancements</h5>
              <p>Enhanced app designs with modern UI/UX principles to captivate users.</p>
          </div>
      </div>

        </Slider>
    </div>
  )
}

export default BannerSlider