import './App.css';
import './Error.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Careers from './Components/Careers';
import Contact from './Components/Contact';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsConditions from './Components/TermsConditions';
import Service1 from './Components/Service1';
import Service2 from './Components/Service2';
import Service3 from './Components/Service3';
import Service4 from './Components/Service4';
import CareerDetails from './Components/CareerDetails';
import WindowScroll from './Components/WindowScroll';
import Error from './Components/Error';

function App() {
  return (
    <BrowserRouter>
      <WindowScroll />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/careerdetails' element={<CareerDetails />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/ppc' element={<Service1 />} />
        <Route path='/social-media-marketing' element={<Service2 />} />
        <Route path='/digital-marketing-services' element={<Service3 />} />
        <Route path='/website-template' element={<Service4 />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
