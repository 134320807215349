import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Layout from './Layout';
import BannerSlider from './BannerSlider';
import IndustrySlider from './IndustrySlider';
import TestimialSleder from './TestimialSleder';
import { Helmet } from 'react-helmet-async';

function Home() {
  return (
    <div>
      <Helmet>
        <title>Best software development and app development in jaipur  | Codezion</title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Best software development  and app development in jaipur  | Codezion" />
        <meta name="twitter:site" content="@codezion" />
        <meta name="twitter:description" content="CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs." />
        <meta name="twitter:image" content="https://www.codezion.com/assets/images/logo.webp" />
        <meta name="twitter:image:alt" content="codezionsoftware" />
        <meta property="og:title" content="Best software development  and app development in jaipur  | Codezion" />
        <meta property="og:site_name" content="codezion" />
        <meta property="og:url" content="https://www.codezion.com/" />
        <meta property="og:description" content="CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.codezion.com/assets/images/logo.webp" />
        <meta name="title" content="Best software development  and app development in jaipur  | Codezion" />
        <meta name="description" content="CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs." />
        <link rel="canonical" href="https://www.codezion.com/"/>
        <meta name="robots" content="index, follow" />

        {/* Product Schema */}

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Best software development and app development in jaipur  | Codezion",
            url: "https://www.codezion.com/",
            image: "https://www.codezion.com/assets/images/ftlogo.webp",
            description: "CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs.",
            brand: "Codezion Software",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              ratingCount: "47",
            },
          })}
        </script>

      {/* Local Business */}

      <script type="application/ld+json">
          {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
                "priceRange": "$$$",
              "Image": "https://www.codezion.com/assets/images/ftlogo.webp",
              "name": "Best software development and app development in jaipur  | Codezion",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "C-188, B-2, Ground Floor,Ayesha Majestic Near LBS College",
              "addressLocality": "Sunder Marg Tilak Nagar, Raja Park",
              "addressRegion": "Jaipur, Rajasthan",
              "postalCode": "302004",
              "addressCountry": "IN"
            },

              "url": "https://www.codezion.com/",
              "telephone": "+9183868 93777"
         })}
      </script>

      <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            image: [
              "https://www.codezion.com/assets/images/ftlogo.webp",
              "https://www.codezion.com/assets/images/ftlogo.webp",
              "https://www.codezion.com/assets/images/ftlogo.webp",
            ],
            name: "Codezion Software",
            address: {
              "@type": "PostalAddress",
              streetAddress: "C-188, B-2, Ground Floor,Ayesha Majestic Near LBS College",
              addressLocality: "Sunder Marg Tilak Nagar, Raja Park",
              addressRegion: "Jaipur, Rajasthan",
              postalCode: "302004",
              addressCountry: "IN",
            },
            review: {
              "@type": "Review",
              reviewRating: {
                "@type": "Rating",
                ratingValue: 4.8,
                bestRating: 47,
              },
              author: {
                "@type": "Person",
                name: "Anil Jangid",
              },
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: 35.230769,
              longitude: -82.379379,
            },
            url: "https://www.codezion.com/",
            telephone: "+9183868 93777",
            servesCuisine: "India",
            priceRange: "Contact us",
            openingHoursSpecification: [
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: ["Monday", "Tuesday"],
                opens: "09:30",
                closes: "19:30",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: ["Wednesday", "Thursday", "Friday"],
                opens: "09:30",
                closes: "19:30",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "Saturday",
                opens: "09:00",
                closes: "19:30",
              },
              {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: "Sunday",
                opens: "00:00",
                closes: "00:00",
              },
            ],
            menu: "https://www.codezion.com/",
          })}
        </script> 

    {/* Organization */}

    <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Codezion Software",
            url: "https://www.codezion.com/",
            logo: "https://www.codezion.com/assets/images/ftlogo.webp",
            email: "info@codezion.com",
            Employees: "50",
            sameAs: [
              "https://www.codezion.com/",
              "https://www.codezion.com/social-media-marketing",
              "https://x.com/codezion_soft",
              "https://www.facebook.com/codezionthemes/",
              "https://www.instagram.com/codezionsoftware/",
              "https://in.pinterest.com/codezion0699/",
            ],
          })}
        </script>

    {/* Website And Webpage  */}

    <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                url: "https://www.codezion.com/",
                name: "Codezion Software",
                description: "CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs.",
                publisher: { "@id": "https://www.codezion.com/#organization" },
                potentialAction: [
                  {
                    "@type": "SearchAction",
                    target: {
                      "@type": "EntryPoint",
                      urlTemplate: "https://www.codezion.com/?s={search_term_string}",
                    },
                    "query-input": "required name=search_term_string",
                  },
                ],
                inLanguage: "en-US",
              },
              {
                "@type": "WebPage",
                url: "https://www.codezion.com/",
                name: "Codezion Software",
                primaryImageOfPage: {
                  "@id": "https://www.codezion.com/assets/images/ftlogo.webp",
                },
              },
            ],
          })}
        </script>

    {/* Professional Service */}

      <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "ProfessionalService",
            priceRange: "contact us",
            name: "Codezion Software",
            url: "https://www.codezion.com/",
            image: "https://www.codezion.com/assets/images/ftlogo.webp",
            description: "CodeZion offers the best software product and app development services in Jaipur. Empower your business with innovative, custom web, game solutions tailored to your needs.",
            telephone: "+9183868 93777",
            areaServed: ["Rajasthan"],
            address: {
              "@type": "PostalAddress",
              streetAddress: "C-188, B-2, Ground Floor,Ayesha Majestic Near LBS College",
              addressLocality: "Sunder Marg Tilak Nagar, Raja Park",
              addressRegion: "Jaipur, Rajasthan",
              postalCode: "302004",
              addressCountry: "IN",
            },
          })}
        </script> 

      </Helmet>

    <Layout/>
  <section className="carrier_cont container-fluid section-padding pt-0">
    <div className="container">
      <div className="row tag_row">
        <div className="col-lg-12 tags_main">
          <span className="tag__name">I'm Looking For : </span>
          <div className="tag-column">
          <span className="tags">Graphic Designer <i className="far fa-angle-double-right"></i></span>
          <span className="tags">Full stack developer <i className="far fa-angle-double-right"></i></span>
          <span className="tags">React Developer <i className="far fa-angle-double-right"></i></span>
          <span className="tags">All <i className="far fa-angle-double-right"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div className="container  hero__container pt-5">
      <div className="row">
        <div className="col-lg-12 col-sm-10 px-5">
          <p className="subtitle text-body-tertiary fw-semibold">IT EXPERTS</p>
          <h1 className="her__title">   
          Transforming <span className="dark_font"> Ideas </span> into
            <span className="dark_font"> Innovative </span> Digital Solutions.
          </h1>

          <Link to="/contact-us" className="btn btn-lg btn-rounded px-3 y-2 bg-white about-btn fw-bold">
            Contact Us <img src="assets/images/arrow-dark.webp" alt="img" />
          </Link>
        </div>
      </div>
    </div>
    <div className="row slide-home">
      <div className="col-lg-2"></div>
      <div className="col-lg-10">
        <BannerSlider/>
      </div>
    </div>
  </section>

  <section className="section-padding workflow-cont pt-0">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-lg-9">
          <div className="workflow-hedding">
            <h1 className="text-center title__workflow">
              Our
              <span className="text-dark fw-700"> Workflow</span>
            </h1>
            <p className="text-center w-75 m-auto mb-5 text-tertiary fw-600">
              Our workflow is simple and well-defined. We as an online
              advertising agency follow multiple steps to create and deliver
              the best solutions based on the needs of clients. Our steps
              include
            </p>
          </div>
        </div>
      </div>
      <div className="row jiop" style={{backgroundColor: '#d6fcff', borderRadius: '10px' }}>
        <div className="col-lg-3 col-md-6 col-sm-6 text-center flex-direction">
          <div className="work-top-bg-color"></div>
          <div className="workflow-card-detail">
            <div className="workflow-image">
              <img src="assets/images/Frame16.webp" alt=''/>
            </div>
            <h6>Requirement Gathering</h6>
            <p>
              We first gather your digital marketing requirements. Gather
              details about your business, target audience, market, goal, etc.
            </p>
          </div>
          <div className="workflow-number">
            <h2 className="mt-2">01</h2>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 text-center">
          <div className="workflow-number">
            <h2 className="mt-2">02</h2>
          </div>
          <div className="workflow-card-detail bo-redus">
            <p>
              After gathering every necessary detail related to your business
              and requirements, we create a unique digital marketing strategy
              for you.
            </p>
            <h6>Create Strategy</h6>
            <div className="workflow-image">
              <img src="assets/images/Frame17.webp" alt=''/>
            </div>
          </div>
          <div className="work-top-bg-color"></div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 text-center flex-direction">
          <div className="work-top-bg-color"></div>
          <div className="workflow-card-detail">
            <div className="workflow-image">
              <img src="assets/images/Frame18.webp" alt=''/>
            </div>
            <h6 className="mt-2">Promote</h6>
            <p>
              Once we create the digital marketing strategy for your business,
              we implement it and promote your brand and services on the
              desired platforms.
            </p>
          </div>
          <div className="workflow-number">
            <h2 className="mt-2">03</h2>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 text-center">
          <div className="workflow-number">
            <h2 className="mt-2">04</h2>
          </div>
          <div className="workflow-card-detail bo-redus">
            <div className="workflow-image">
              <img src="assets/images/Frame19.webp" alt=''/>
            </div>
            <h6>Reporting</h6>
            <p>
              We gather the analytic tool to access each important detail
              related to your digital marketing campaign and send a detailed
              report to you.
            </p>
          </div>
          <div className="work-top-bg-color"></div>
        </div>
      </div>
    </div>
  </section>
{/* <!-- ---------- Marketing Services ------------- --> */}
  <section className="container-fluid marketing section-padding pt-0">
    <div className="container px-lg-5">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h3 className="subtitle">Digital Marketing Services</h3>
          <h1 className="main__title">   
            Grow Your <span className="font_dark">Business </span> with the Best
            <span className="font_dark"> Digital Marketing Agency </span>
          </h1>
          <p className="main__desc">
          Boost your brand with Codezion's expert digital marketing services. From SEO to social media, we deliver tailored solutions for maximum online growth!
          </p>

          <Link to="/digital-marketing-services" className="btn-thm-main learn-more">
            Learn More <i className="far fa-angle-double-right"></i>
          </Link>
        </div>
        <div className="col-lg-6 col-sm-12 img-mar">
          <img src="assets/images/digital.webp" alt="img" className="rounded-5" />
        </div>
      </div>
    </div>
  </section>

  {/* ---------- */}
  <section className="services container-fluid section-padding">
    <div className="container main__service px-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="row pt-5">
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/1.webp" className="box-icon" alt="img"/> Digital Marketing
                </h3>
                <p className="service__desc">
                Improve your online presence with our customized digital marketing solutions. From SEO strategies to social media campaigns, we optimize your brand for digital success.
                </p>

                <Link to="/digital-marketing-services" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/2.webp" className="box-icon" alt="img"/>PHP Development
                </h3>
                <p className="service__desc">
                Empower your web applications with robust PHP development. Our expert team provides scalable, secure, high-performance solutions per your business needs.
                </p>

                <Link to="/social-media-marketing" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/3.webp" className="box-icon" alt="img"/>Web Development
                </h3>
                <p className="service__desc">
                Create visually attractive and fully functional websites with our web development expertise. We turn ideas into engaging digital experiences for your audience.

                </p>

                <Link to="/digital-marketing-services" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/4.webp" className="box-icon" alt="img"/> Mobile App Development
                </h3>
                <p className="service__desc">
                Transform your ideas into dynamic mobile applications. Our skilled developers create user-friendly, feature-rich apps for iOS and Android platforms.

                </p>

                <Link to="/digital-marketing-services" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/5.webp" className="box-icon" alt="img"/> Magento Development
                </h3>
                <p className="service__desc">
                Use the power of e-commerce with Magento development. We build scalable and efficient online stores for your business, from customization to optimization.
                </p>

                <Link to="/ppc" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 cards_cont">
              <div className="content">
                <h3 className="service__title">
                  <img src="assets/images/icons/6.webp" className="box-icon" alt="img"/> Shopify Development
                </h3>
                <p className="service__desc">
                Establish your online store with Shopify development. Our expert designers and developers provide visually appealing, user-friendly e-commerce solutions.

                </p>

                <Link to="/digital-marketing-services" className="service__link">
                  Learn more <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <IndustrySlider/>
  <section className='section-padding hero__container product-section mt-5'>
    <div className="container  ">
    <div className='row justify-content-center'>
      <div className="col-lg-10">
        <h1 className="text-center title__milestone">Our
        <span className="text-dark fw-bold"> Product</span></h1>
      </div>
    </div>
    <div className="row pt-4">
      <div className="col-lg-3 col-sm-6">
        <div className="product-box">
          <div className="product-image">
            <img src="assets/images/product/1.webp" alt="img" />
          </div>
          <div className="product-text">
            <Link to='#'>Godaharidev Jewells</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="product-box">
          <div className="product-image">
            <img src="assets/images/product/2.webp" alt="img" />
          </div>
          <div className="product-text">
            <Link to=''>Tyresolio</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="product-box">
          <div className="product-image">
            <img src="assets/images/product/3.webp" alt="img" />
          </div>
          <div className="product-text">
            <Link to='#'>Jewllio</Link>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-sm-6">
        <div className="product-box">
          <div className="product-image">
            <img src="assets/images/product/4.webp" alt="img" />
          </div>
          <div className="product-text">
            <Link to='#'>Radhika Garments</Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
  {/* ---testimonial--- */}
  <section className='section-padding services  mt-0 testimonial-section p-0'>
    <div className="container">
    <h1 className="text-center title__milestone">Our
    <span className="text-dark fw-bold"> Testimonials</span></h1>
    <TestimialSleder/>
    </div>
  </section>
  {/* testimonial end */}
  
  {/* <!-- =============== Section Form ===================== --> */}
  {/* footer */}
  <Footer/>
    </div>
  )
}

export default Home